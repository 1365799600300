<template>

  <div :class="'fI mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>LOGIN</translate> ~</div>
      </div>
    </div>

    <div>

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div id='box-container' style="width: 100%; overflow: hidden;">

        <FormulateForm
          v-model='values'
          :errors='errors'
          @submit="confirmOtp()"
          #default="{ hasErrors }"
        >
          <div id="OtpMethodForm">
            <div v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
              <translate>Otp confirm error:</translate> { errors.root }
            </div>
            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper">
              <span v-translate>Please <b>enter the OTP code</b> sent to your email.</span><br/><br/>
              <FormulateInput
                  v-focus
                  class="landingPageInput otpInput"
                  name='otp'
                  validation='bail|number|min:6,length'
                  type="text"
                  size='6'
                  v-on:input="inputWatcher"
                  maxlength="6"
                  autocomplete="off"
                  placeholder='. . . . . .'
                  :validation-messages="{
                    required: $gettext('Otp is required'),
                    number: $gettext('Otp must be a number'),
                    min: $gettext('Otp must be 6 digits long.')
                  }"
              />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if='hasErrors' disabled class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><i class="fas fas-xsBtn fa-lock fas-left"></i><translate>LOGIN</translate></button>
              <button v-else class='landingPageButton btn btn-success trans pulsate fI' name='confirm_otp' :value="$gettext('Confirm')"><i class="fas fas-xsBtn fa-lock fas-left"></i><translate>LOGIN</translate></button>
              <span class='loginSubBtn w-50 mt-2 btn-small' style="opacity: 0.85;">
                <br /><br />
                <translate>Having issues logging in?</translate><br /><a class="color03" href="https://help.slushpool.com/support/tickets/new" target="_blank"><b><translate>Contact support.</translate></b></a>
              </span>
            </div>
          </div>

        </FormulateForm>
      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { OTP__CONFIRM } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  data () {
    return {
      verified_otp: (this.$route.query.verified_otp ? this.$route.query.verified_otp : null),
      values: {
        otp: null
      },
      errors: {}
    }
  },
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  created () {
    if (this.verified_otp && this.verified_otp.length === 6) {
      this.values.otp = this.verified_otp
      this.$store.dispatch(OTP__CONFIRM, this.values.otp).then((response) => {
        this.$router.push({ name: 'overview' })
      }).catch((err) => {
        this.handleErrors(err)
      })
    }
  },
  methods: {
    handleErrors (err) {
      const otpStatus = err.response.headers['x-2fa-status']
      const status = parseInt(err.response.status)

      if (status === 400) {
        if (otpStatus === '2FA_INVALID_OTP') {
          this.errors = { otp: ['Otp is invalid'] }
        } else if (otpStatus === '2FA_GENERAL_ERROR') {
          this.errors = { otp: ['Otp mode was invalid'] }
        } else {
          this.errors = { root: err.response.data.detail }
        }
      } else if (status === 422) {
        const errorsObject = { otp: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = errorsObject
      } else if (status === 403) {
        if (otpStatus === '2FA_INVALID_STATE') {
          this.errors = { otp: ['2FA mismatch between login/2FA calls'] }
        }
        if (otpStatus === '2FA_SETUP_PENDING') {
          this.errors = { otp: ['2FA setup pending'] }
        }
      } else if (status === 404) {
        this.errors = { otp: ['User not found'] }
      }
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    inputWatcher () {
      if (this.values.otp && this.values.otp.length === 6) {
        this.confirmOtp()
      }
    },
    confirmOtp () {
      this.$store.dispatch(OTP__CONFIRM, this.values.otp).then((response) => {
        this.$router.push({ name: 'overview' })
      }).catch((err) => {
        this.handleErrors(err)
      })
    }
  }
}
</script>
